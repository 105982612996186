export const useTime = () => {
  const timeData = ref<string>('');
  const timeCurrent = ref<string>('');

  const updateTime = () => {
    const date = new Date();

    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const curentDate = date.getDate().toString().padStart(2, '0');
    const currentMonth = (date.getMonth() + 1).toString().padStart(2, '0');
    const currentYear = date.getFullYear().toString();

    timeData.value = `${curentDate}.${currentMonth}.${currentYear}`;
    timeCurrent.value = `${hours}:${minutes}:${seconds}`;
  };

  return {
    timeData,
    timeCurrent,
    updateTime,
  };
};
