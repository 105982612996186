<template>
  <div class="event-wrapper">
    <section class="event" id="events">
      <h2 class="event-title font--h2">Earn points</h2>
      <div class="event-content">
        <div class="card card1">
          <div class="card-text-wrapper">
            <h3 class="card-title font--b1--b">Swipe Cards - Loot Points</h3>
            <h4 class="card-subtitle font--b2">
              Like or Dislike news will personalize your feed and drop bonus
              chests!
            </h4>
          </div>
          <nuxt-img
            class="card-image"
            src="/v2/event-card-swipe.png"
            alt=""
          ></nuxt-img>
        </div>

        <div class="card card2">
          <div class="card-text-wrapper">
            <h3 class="card-title font--b1--b">Referral Point Program</h3>
            <h4 class="card-subtitle font--b2">
              Invite friends and earn up to 20% points of referred friends!
            </h4>
            <h4 class="card-subtitle font--b2">Just share a link :))</h4>
          </div>
          <nuxt-img
            class="card-image"
            src="/v2/event-card-points.png"
            alt=""
          ></nuxt-img>
        </div>

        <div class="card card3">
          <div class="card-text-wrapper">
            <h3 class="card-title font--b1--b">Do Quests for Bounty</h3>
            <h4 class="card-subtitle font--b2">
              Complete simple tasks and get rewards.
            </h4>
          </div>
          <nuxt-img
            class="card-image"
            src="/v2/event-card-social.png"
            alt=""
          ></nuxt-img>
        </div>

        <div class="card card4">
          <div class="card-text-wrapper">
            <h3 class="card-title font--b1--b">Hold Tokens for More Tokens</h3>
            <h4 class="card-subtitle font--b2">
              Be one of top lucky $NF holders to get your tokens Grow or even be
              Doubled!
            </h4>
            <h4 class="card-subtitle font--b2">Every week on Friday</h4>
          </div>
          <nuxt-img
            class="card-image"
            src="/v2/event-card-hold.png"
            alt=""
          ></nuxt-img>
        </div>
        <div class="card card5">
          <div class="card-text-wrapper">
            <h3 class="card-title font--b1--b">Want More Rewards?</h3>
            <h4 class="card-subtitle font--b2">
              Newtify raffles prizes for active users—stay updated on our events
              page and social media!
            </h4>
            <nuxt-link to="/events" class="card-link hover-scale-light font--b2"
              >Check Events</nuxt-link
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.event-wrapper {
  position: relative;
  width: 100%;
}

.event {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.event-title {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.event-content {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 384px);
  grid-template-areas:
    'card1 card1 card2 card2 card3 card3'
    'card4 card4 card4 card5 card5 card5';
  gap: 24px;
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--card-shadow);
  overflow: hidden;
}

.card-text-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card-title {
  margin-bottom: 8px;
}

.card-subtitle {
  color: var(--grey-800);
  width: 100%;
  max-width: 320px;
  margin-bottom: 11px;
}

.card1 {
  grid-area: card1;
}

.card1 .card-image {
  transform: translate(2px, 20px);
  align-self: center;
}

.card1 .card-subtitle {
  text-wrap: balance;
}

.card2 {
  grid-area: card2;
}

.card2 .card-image {
  align-self: center;
}

.card3 {
  grid-area: card3;
}

.card3 .card-image {
  align-self: center;
  transform: scale(1.12);
}

.card4 {
  grid-area: card4;
}

.card4 .card-image {
  align-self: flex-end;
  transform: translate(10%, -32%);
  max-width: 287px;
}

.card5 .card-subtitle {
  max-width: 400px;
  margin-bottom: 15px;
}

.card5 {
  grid-area: card5;
  background-image: url('/v2/event-card-reward.png');
  background-repeat: no-repeat;
  background-position: 38% 100%;
  background-size: 89%;
}

.card-link {
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  border-radius: 40px;
  background-color: var(--primary-orange);
  color: var(--white);
  display: flex;
  height: 44px;
  padding: 0 24px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .event {
    padding: 40px 24px;
  }

  .event-title {
    margin-bottom: 24px;
  }

  .event-content {
    width: 100%;
    max-width: 750px;
    grid-template-areas:
      'card1'
      'card2'
      'card3'
      'card4'
      'card5';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    gap: 16px;
  }

  .card {
    padding: 24px 24px 0 24px;
  }

  .card1 {
    height: 384px;
  }

  .card1 .card-image {
    transform: unset;
    max-width: 330px;
    align-self: center;
  }

  .card2 {
    height: 295px;
    padding: 24px;
  }

  .card2 .card-image {
    transform: unset;
    max-width: 307px;
    align-self: center;
  }

  .card3 {
    height: 340px;
    padding: 24px;
  }

  .card3 .card-image {
    transform: unset;
    max-width: 330px;
    align-self: center;
  }

  .card4 {
    height: 384px;
  }
  .card4 .card-image {
    align-self: center;
    transform: translate(0%, -3%);
    max-width: 237px;
  }

  .card5 {
    height: 342px;
    background-repeat: no-repeat;
    background-position: 47% 103%;
    background-size: 85%;
  }
}

@media (max-width: 400px) {
  .card1 .card-image,
  .card2 .card-image,
  .card3 .card-image {
    max-width: 100%;
  }

  .card4 .card-subtitle {
    margin: 0;
  }

  .card4 .card-image {
    transform: unset;
    max-width: 200px;
  }
}
</style>
