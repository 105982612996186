<template>
  <div class="community-wrapper">
    <section class="community">
      <h2 class="community-title font--h2">COMMUNITY</h2>
      <div class="community-content">
        <nuxt-link to="https://x.com/Newtifyapp" target="_blank" class="card">
          <base-icon name="base/social_x" size="80px" filled />
          <div class="card-text-wrapper">
            <span class="card-text-title font--h3">X WEB3 Alerts</span>
            <span class="card-text-subtitle font--b2"
              >6000+ Crypto Entusiasts</span
            >
          </div>
          <base-icon class="card-arrow" name="base/arrow" size="24px" filled />
        </nuxt-link>

        <nuxt-link to="https://t.me/newtify" target="_blank" class="card">
          <base-icon name="base/social_telegram" size="80px" filled />
          <div class="card-text-wrapper">
            <span class="card-text-title font--h3">TG NEWTIFY Hub</span>
            <span class="card-text-subtitle font--b2"
              >12 000+ Web3 Pioneers</span
            >
          </div>
          <base-icon class="card-arrow" name="base/arrow" size="24px" filled />
        </nuxt-link>

        <nuxt-link
          to="https://discord.gg/WYWT9rKh6z"
          target="_blank"
          class="card"
        >
          <base-icon name="base/social_discord" size="80px" filled />
          <div class="card-text-wrapper">
            <span class="card-text-title font--h3">Discord Loot Club</span>
            <span class="card-text-subtitle font--b2">6000+ Loot Lovers</span>
          </div>
          <base-icon class="card-arrow" name="base/arrow" size="24px" filled />
        </nuxt-link>

        <nuxt-link
          to="https://www.youtube.com/@newtifyio"
          target="_blank"
          class="card"
        >
          <base-icon name="base/social_youtube" size="80px" filled />
          <div class="card-text-wrapper">
            <span class="card-text-title font--h3">OUR YT Guides</span>
            <span class="card-text-subtitle font--b2"
              >How-to earn and cash out</span
            >
          </div>
          <base-icon class="card-arrow" name="base/arrow" size="24px" filled />
        </nuxt-link>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.community-wrapper {
  position: relative;
  width: 100%;
}

.community {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.community-title {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.community-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  background: var(--primary-orange);
  padding: 16px 24px;
  gap: 16px;
  width: 100%;
  max-width: 588px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.card-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
}

.card-text-title {
  color: var(--white);
}

.card-text-subtitle {
  color: var(--white);
}

.card-arrow {
  opacity: 0;
  position: absolute;
  top: 24px;
  right: 24px;
  transition: all 0.3s ease;
}

.card:hover {
  background: var(--black);
}

.card:hover .card-arrow {
  opacity: 1;
}

@media (max-width: 1455px) {
  .card {
    max-width: 450px;
  }
}

@media (max-width: 1200px) {
  .community {
    max-width: 750px;
    padding: 40px 24px;
  }

  .community-content {
    gap: 16px;
  }

  .card {
    padding: 16px;
  }

  .card :deep(.base-icon) {
    width: 64px;
    height: 64px;
  }

  .card :deep(.base-icon) .icon {
    width: 64px;
    height: 64px;
  }

  .card :deep(.base-icon) .icon svg {
    width: 64px;
    height: 64px;
  }

  .card-arrow {
    display: none;
  }
}
</style>
