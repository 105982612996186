<template>
  <div class="main-content">
    <v2main-hero />
    <v2main-event />
    <v2main-withdraw />
    <v2main-swap />
    <v2main-trust />
    <v2main-community />
    <v2main-team />
    <v2main-review />
    <v2main-banner />
  </div>
</template>

<script setup lang="ts">
import { BASE_SEO_PROPERTIES } from "~/services/SEO/getPublicSeo";

useSeoMeta({
  title: "Newtify",
  description: "Newtify – your AI companion in the world of crypto news",
  ogTitle: "Newtify",
  ogDescription: "Newtify – your AI companion in the world of crypto news",
  ...BASE_SEO_PROPERTIES,
});
</script>

<style scoped>
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
