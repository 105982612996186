<template>
  <div class="team-wrapper">
    <section class="team">
      <h2 class="team-title font--h2">Our team</h2>
      <div class="team-content">
        <v2main-team-card
          image="/v2/ArtemKushneryk.png"
          name="Artem"
          lastname="Kushneryk"
          position="Founder"
          linkedin="https://linkedin.com/in/artem-kushneryk/"
        />

        <v2main-team-card
          image="/v2/VladimirHenri.png"
          name="Vladimir"
          lastname="Henri"
          position="Operations Officer"
          linkedin="https://www.linkedin.com/in/lokiboi/"
        />

        <v2main-team-card
          image="/v2/VeranikaSasnouskaya.png"
          name="Veranika"
          lastname="Sasnouskaya"
          position="Product Manager"
          linkedin="https://www.linkedin.com/in/veranika-sasnouskaya"
        />
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.team-wrapper {
  position: relative;
  width: 100%;
}

.team {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.team-title {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.team-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
}

@media (max-width: 1200px) {
  .team {
    padding: 40px 24px 10px;
  }

  .team-content {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    justify-content: flex-start;
    padding-bottom: 30px;
  }

  .team-content::-webkit-scrollbar {
    display: none;
  }
}
</style>
