import type { PostExchangeDTO } from '~/types/exchange';
import { apiGet, apiPost } from '~/services/api';
import getRoute from '~/utilities/configs/apiMap';

export const ExchangeApi = {
  getUser: (id: string) => {
    return apiGet({
      url: getRoute({ endpont: 'get_user_by_id', id: id }),
      headers: {
        Authorization: id,
      },
    });
  },

  postExchange: ({ userID, userPoints, userAccount, token }: PostExchangeDTO) => {
    return apiPost({
      url: getRoute({ endpont: 'post_exchange' }),
      body: {
        userId: userID,
        walletAddress: userAccount,
        amount: userPoints,
      },
      headers: {
        recaptcha: token,
      },
    });
  },

  getUserReferralLink: (id: string) => {
    return apiGet({
      url: getRoute({ endpont: 'get_user_referral_link' }),
      headers: {
        Authorization: id,
      },
    });
  },
};
