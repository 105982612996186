<script setup lang="ts">
import { truncateMiddle } from '~/utilities/helpers/truncate-middle';
import { useWithdrawStore } from '~/stores/withdraw.store';
import { useMetaMaskStore } from '~/stores/meta-mask.store';
import { useClipboard } from '~/utilities/helpers/clipboard';

const withdrawStore = useWithdrawStore();
const metaMaskStore = useMetaMaskStore();

const { copy, isCopied } = useClipboard();

const handleCopy = () => {
  copy(withdrawStore.transactionHash ?? '');
};

interface Emits {
  (e: 'reset'): void;
}

const emit = defineEmits<Emits>();

const onClose = () => {
  if (withdrawStore.transactionStatus === 'success') {
    emit('reset');
    return;
  }

  withdrawStore.openTransactionScreen = false;
};

const openPopup = ref(false);
</script>

<template>
  <div class="transaction-wrapper">
    <div class="transaction-header">
      <button type="button" class="transaction-button" @click.prevent="onClose">
        <base-icon name="base/chevron_left" size="24px" filled></base-icon>
      </button>
      <h2 class="transaction-title font--b1--b">Transaction</h2>
    </div>

    <div class="transaction-info">
      <div class="transaction-status">
        <transition name="fade" mode="out-in">
          <div
            v-if="withdrawStore.transactionStatus === 'pending'"
            class="transaction-status-box transaction-status-pending"
          >
            <base-icon name="base/pending_v2" size="16px" filled></base-icon>
            <span class="font--b3">Pending</span>
          </div>

          <div
            v-else-if="withdrawStore.transactionStatus === 'failed'"
            class="transaction-status-content transaction-status-error"
          >
            <div class="transaction-status-box">
              <base-icon name="base/error" size="16px" filled></base-icon>
              <span class="font--b3">Failed</span>
            </div>
            <span class="transaction-status-message font--b3">{{
              withdrawStore.transactionError
            }}</span>
          </div>

          <div
            v-else-if="withdrawStore.transactionStatus === 'success'"
            class="transaction-status-content transaction-status-success"
          >
            <div class="transaction-status-box">
              <base-icon name="base/valid" size="16px" filled></base-icon>
              <span class="font--b3">Confirmed</span>
            </div>
            <span class="transaction-status-message font--b3"
              >Exchange in Progress. Check your wallet in 15 mins</span
            >
          </div>
        </transition>
      </div>

      <div class="transaction-info-hash">
        <span class="font--b3">Tx hash:</span>

        <div
          v-if="withdrawStore.transactionStatus === 'success'"
          class="transaction-info-hash-wrapper"
        >
          <nuxt-link
            :to="`https://polygonscan.com/tx/${withdrawStore.transactionHash}`"
            target="_blank"
            class="transaction-info-hash-value font--b3"
            :class="{ 'transaction-info-hash-value-copy': isCopied }"
            >{{
              truncateMiddle(withdrawStore.transactionHash ?? '', 10)
            }}</nuxt-link
          >
          <transition name="fade" mode="out-in">
            <base-icon
              v-if="!isCopied"
              class="transaction-info-hash-copy"
              name="base/copy_gray"
              size="16px"
              filled
              @click="handleCopy"
            ></base-icon>
            <base-icon v-else name="base/valid" size="16px" filled></base-icon>
          </transition>
        </div>
      </div>

      <div class="transaction-info-time">
        <span class="font--b3">Date and time</span>

        <div class="transaction-info-time-wrapper">
          <span class="transaction-info-text">{{
            withdrawStore.timeData
          }}</span>
          <span class="transaction-info-text transaction-info-time-divider">
            |
          </span>
          <span class="transaction-info-text">{{
            withdrawStore.timeCurrent
          }}</span>
        </div>
      </div>
    </div>

    <div class="transaction-box-wrapper">
      <div class="transaction-box separator">
        <base-icon
          name="base/newtify_full"
          size="24px"
          class="newtify-icon"
          filled
        ></base-icon>
        <div class="transaction-box-content">
          <span class="font--b2">{{
            truncateMiddle(withdrawStore.userID, 5)
          }}</span>

          <span class="font--b3 transaction-box-content-value"
            >-{{ withdrawStore.userPoints }} Points</span
          >
        </div>
      </div>

      <div class="transaction-box">
        <base-icon
          name="base/meta_mask"
          size="25px"
          class="metamask-icon"
          filled
        ></base-icon>
        <div class="transaction-box-content">
          <span class="transaction-box-content-wallet font--b2">{{
            truncateMiddle(metaMaskStore.metaMaskUserWallet, 5)
          }}</span>

          <span class="font--b3 transaction-box-content-value"
            >+{{ withdrawStore.userReceive }} NF</span
          >
        </div>
      </div>
      <base-icon
        class="transaction-move-icon"
        name="base/arrow_right"
        size="24px"
        filled
      ></base-icon>
    </div>
    <div
      v-if="withdrawStore.transactionStatus === 'success'"
      class="transaction-helper"
    >
      <span class="transaction-helper-text font--b3"
        >Don’t see your token in MetaMask?</span
      >
      <button
        class="transaction-helper-button font--b3"
        @click="openPopup = true"
      >
        Add it to your MetaMask
      </button>
    </div>
    <base-popup v-model="openPopup">
      <popup-add-nf-token @close="openPopup = false" />
    </base-popup>
  </div>
</template>

<style scoped lang="scss">
.transaction-wrapper {
  position: relative;
  width: 100%;
  max-width: 588px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--card-shadow);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 16px;
}

.transaction-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.transaction-title {
  width: 100%;
  text-align: center;
  padding-right: 24px;
}

.transaction-button {
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  padding: 0;
}

.transaction-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}

.transaction-status {
  width: 100%;
}

.transaction-status-box {
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 15px;
}

.transaction-status-pending.transaction-status-box {
  background-color: var(--bg-secondary-orange);
  color: var(--primary-orange);
  max-width: 105px;
}

.transaction-status-error .transaction-status-box {
  background-color: var(--bg-secondary-red);
  color: var(--negative);
}

.transaction-status-error .transaction-status-message {
  color: var(--negative);
}

.transaction-status-success .transaction-status-box {
  background-color: var(--bg-secondary-green);
  color: var(--positive);
}

.transaction-status-success .transaction-status-message {
  color: var(--positive);
}

.transaction-status-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.transaction-status-message {
  width: 100%;
  text-align: right;
}

.transaction-info-hash {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--grey-800);
}

.transaction-info-hash-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.transaction-info-hash-value {
  color: var(--grey-800);
  text-decoration-line: underline;
  text-underline-offset: 4px;
  transition: all 0.3s ease;
}

.transaction-info-hash-copy {
  cursor: pointer;
}

.transaction-info-time {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: var(--grey-800);
}

.transaction-info-time-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.transaction-info-time-divider {
  margin: 0 4px;
}

.transaction-box-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid var(--grey-200);
}

.transaction-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  padding: 14px 23px;
}

.separator {
  border-right: 1px solid var(--grey-200);
}

.transaction-box-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.transaction-box-content-wallet {
  text-decoration-line: underline;
  text-underline-offset: 6px;
}

.transaction-box-content-value {
  color: var(--primary-orange);
}

.transaction-helper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.transaction-helper-text {
  text-align: left;
  color: var(--grey-800);
}

.transaction-helper-button {
  width: fit-content;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  padding: 0;
  color: var(--primary-orange);
  text-decoration: underline;
  text-underline-offset: 4px;
}

.transaction-move-icon {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1200px) {
  .transaction-wrapper {
    padding: 24px;
    gap: 14px;
  }

  .transaction-status-content {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .transaction-status-message {
    width: fit-content;
    text-align: left;
  }

  .transaction-box-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .transaction-box {
    height: 78px;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 24px;
  }

  .transaction-box :deep(.base-icon) {
    width: 20px;
    height: 20px;
  }

  .transaction-box :deep(.base-icon) .icon {
    width: 20px;
    height: 20px;
  }

  .transaction-box :deep(.base-icon) .icon svg {
    width: 20px;
    height: 20px;
  }

  .separator {
    border-right: unset;
    border-bottom: 1px solid var(--grey-200);
  }

  .transaction-helper {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .transaction-move-icon {
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
</style>
