<script setup lang="ts">
import { useWithdrawStore } from '~/stores/withdraw.store';
import { useClipboard } from '~/utilities/helpers/clipboard';

const showInstruction = ref(false);
const openPopup = ref(false);

const withdrawStore = useWithdrawStore();

const onClose = () => {
  withdrawStore.showSuccesBanner = false;
};

const { utm_source, utm_medium, utm_campaign } = useUtmParams();

const googlePlayUrl = computed(() => {
  return `http://play.google.com/store/apps/details?id=io.newtify.app&referrer=utm_source%3D${utm_source}%26utm_medium%3D${utm_medium}%26utm_campaign%3D${utm_campaign}`;
});

const { copy, isCopied } = useClipboard();

const handleCopy = () => {
  copy(withdrawStore.userReferralLink);
};

const share = async () => {
  // Check if Web Share API is supported
  if (navigator && navigator.share) {
    try {
      await navigator.share({
        title: 'Newtify App',
        text: 'Check this out!',
        url: withdrawStore.userReferralLink, // Shares the current page URL
      });
      console.log('Shared successfully');
    } catch (error) {
      console.error('Error sharing:', error);
    }
  } else {
    console.log('Web Share API not supported');
    copy(withdrawStore.userReferralLink);
  }
};
</script>

<template>
  <div class="withdraw-wrapper">
    <section id="withdraw" class="withdraw">
      <h2 class="withdraw-title font--h2">Withdraw</h2>
      <h3 class="withdraw-subtitle font--h3">points to nf token</h3>
      <div class="withdraw-content-wrapper">
        <div class="withdraw-content">
          <withdraw-form />
          <transition name="fade" mode="out-in">
            <base-button-show
              v-if="withdrawStore.transactionStatus !== 'success'"
              v-model:is-shown="showInstruction"
            >
              <template #show>View video-instruction</template>
              <template #hide>Hide video-instruction</template>
            </base-button-show>
            <nuxt-link
              v-else
              to="/#footer"
              class="withdraw-contact-link font--b2 hover-scale-light"
              >Problem? Contact us</nuxt-link
            >
          </transition>
        </div>

        <transition name="fade">
          <div v-if="showInstruction" class="instruction-wrapper">
            <iframe
              width="315"
              height="560"
              src="https://www.youtube.com/embed/BIEQZyTbQeE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </transition>
      </div>

      <transition name="fade">
        <div v-if="withdrawStore.showSuccesBanner" class="success-banner">
          <p class="success-banner-text font--h3">
            Happy to see you as the NF-holder
          </p>
          <div class="success-banner-buttons-wrapper">
            <button
              class="success-banner-button success-banner-button-rate success-banner-button-desktop font--b2 hover-scale-light"
              @click="openPopup = true"
            >
              Rate our app
            </button>

            <nuxt-link
              :to="
                $device.isIos
                  ? 'https://apps.apple.com/us/app/newtify-io/id6482849312'
                  : googlePlayUrl
              "
              class="success-banner-button success-banner-button-rate success-banner-button-mobile font--b2"
              target="_blank"
            >
              Rate our app
            </nuxt-link>

            <button
              v-if="$device.isDesktop"
              target="_blank"
              class="success-banner-button success-banner-button-share font--b2"
              @click="handleCopy"
            >
              {{ isCopied ? 'Invite copied' : 'Share app with friends' }}
              <base-icon v-if="!isCopied" name="base/copy_big" size="24px" filled />
              <base-icon v-else name="base/valid_white" size="24px" filled />
            </button>
            <button
              v-else
              class="success-banner-button success-banner-button-share font--b2"
              @click="share"
            >
            {{ isCopied ? 'Invite copied' : 'Share app with friends' }}
            </button>

          </div>
          <button class="success-banner-close" @click="onClose">
            <base-icon
              name="base/close_gray"
              size="24px"
              color="var(--white)"
            />
          </button>
        </div>
      </transition>
    </section>
    <base-popup v-model="openPopup">
      <popup-get-app @close="openPopup = false" />
    </base-popup>
  </div>
</template>

<style scoped lang="scss">
.withdraw-wrapper {
  position: relative;
  width: 100%;
}

.withdraw {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.withdraw-title {
  width: 100%;
  text-align: center;
}

.withdraw-subtitle {
  color: var(--primary-orange);
  text-align: center;
  margin-bottom: 40px;
}

.withdraw-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.withdraw-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.withdraw-contact-link {
  color: var(--primary-orange);
}

.instruction-wrapper {
  width: 100%;
  max-width: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instruction-wrapper iframe {
  border-radius: 32px;
}

.success-banner {
  position: relative;
  width: 100%;
  border-radius: 24px;
  background: var(--primary-orange);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 48px;
  margin-top: 70px;
}

.success-banner-text {
  color: var(--white);
}

.success-banner-buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  max-width: 422px;
}

.success-banner-button {
  width: fit-content;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px 24px;
  text-align: center;
  border-radius: 40px;
  text-decoration: none;
  flex-shrink: 0;
}

.success-banner-button-rate {
  background-color: var(--white);
  color: var(--primary-orange);
}

.success-banner-button-share {
  width: 100%;
  max-width: 257px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: var(--black);
  color: var(--white);
  transition: all 0.3s ease; 
}

.success-banner-close {
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
}

.success-banner-button-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .withdraw {
    padding: 40px 24px;
  }

  .withdraw-subtitle {
    margin-bottom: 24px;
  }

  .withdraw-content-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .withdraw-contact-link {
    text-underline-offset: 4px;
  }

  .success-banner {
    margin-top: 40px;
    border-radius: 16px;
    padding: 32px 24px;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .success-banner-buttons-wrapper {
    flex-direction: column;
    width: 100%;
    gap: 9px;
  }

  .success-banner-button {
    width: 100%;
    max-width: 500px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .success-banner-button-desktop {
    display: none;
  }

  .success-banner-button-mobile {
    display: flex;
  }
}
</style>
