import { ExchangeService } from '~/services/exchange.service';
import { useMetaMaskStore } from '~/stores/meta-mask.store';
import type { User, UserReferralLink } from '~/types/exchange';
import { useTime } from '~/composables/use-time';
import { FetchError } from 'ofetch';

export const useWithdrawStore = defineStore('withdraw', () => {
  const metaMaskStore = useMetaMaskStore();
  const userID = ref('');
  const userPoints = ref<number | null>(null);
  const isValid = ref(false);
  const isChecking = ref(false);
  const receiveUserPoints = ref(0);
  const getReceivedUserPoints = computed(() => receiveUserPoints.value);
  const userReferralLink = ref('');

  const openTransactionScreen = ref(false);
  const transactionStatus = ref<'pending' | 'failed' | 'success' | null>(null);
  const transactionError = ref('');
  const transactionHash = ref<string | null>(null);
  const showSuccesBanner = ref(false);

  const { timeData, timeCurrent, updateTime } = useTime();

  const userReceive = computed(() => {
    if (!userPoints.value) {
      return '0';
    }
    return (userPoints.value / 5000).toFixed(2);
  });

  async function getUserByID() {
    try {
      isChecking.value = true;
      const response = (await ExchangeService.getUserByID(
        userID.value,
      )) as User;
      isValid.value = response !== null;

      if (response) {
        receiveUserPoints.value = response.points;
      }
    } catch (error) {
      isValid.value = false;
      console.error(error);
    } finally {
      isChecking.value = false;
    }
  }

  async function withdrawPoints(token: string) {
    const data = {
      userID: userID.value,
      userPoints: userPoints.value ?? 0,
      userAccount: metaMaskStore.metaMaskUserWallet,
      token,
    };

    try {
      updateTime();
      transactionStatus.value = 'pending';
      openTransactionScreen.value = true;
      const response: any = await ExchangeService.ExhangePoints(data);
      await getReferralLink();
      transactionHash.value = response.hash;
      transactionStatus.value = 'success';
      showSuccesBanner.value = true;
    } catch (e) {
      transactionError.value =
        e instanceof FetchError
          ? e.data?.message ?? 'Network issues'
          : 'Something went wrong';
      transactionStatus.value = 'failed';
    }
  }

  async function getReferralLink() {
    if(!userID.value) return;
    try {
      const response = await ExchangeService.getUserReferralLink(userID.value);
      userReferralLink.value = (response as UserReferralLink).link;
    } catch (e) {
      transactionError.value =
        e instanceof FetchError
          ? e.data?.message ?? 'Network issues'
          : 'Something went wrong';
      transactionStatus.value = 'failed';
    }
  }

  return {
    userID,
    userPoints,
    userReceive,
    isValid,
    isChecking,
    receiveUserPoints,
    timeData,
    timeCurrent,
    openTransactionScreen,
    transactionStatus,
    transactionError,
    transactionHash,
    showSuccesBanner,
    getReceivedUserPoints,
    userReferralLink,
    getUserByID,
    withdrawPoints,
    getReferralLink,
  };
});
