<script setup lang="ts">
import useUtmParams from '~/composables/use-utm-params';
import { Vue3Lottie } from 'vue3-lottie';

const { utm_source, utm_medium, utm_campaign } = useUtmParams();

const googlePlayUrl = computed(() => {
  return `http://play.google.com/store/apps/details?id=io.newtify.app&referrer=utm_source%3D${utm_source}%26utm_medium%3D${utm_medium}%26utm_campaign%3D${utm_campaign}`;
});
</script>

<template>
  <div class="hero-wrapper">
    <section class="hero">
      <div class="content">
        <h1 class="title font--h1">
          <span class="title-highlight">Newtify</span><br >
          Swipe to earn
        </h1>
        <p class="subtitle font--b1">
          App that rewards you for reading web3 news
        </p>
        <div class="download-buttons-wrapper">
          <nuxt-link
            v-if="$device.isIos || $device.isDesktop"
            to="https://apps.apple.com/us/app/newtify-io/id6482849312"
            class="download-button hover-scale-light"
            target="_blank"
          >
            <Icon size="48" name="icons:app-store-button" class="" />
          </nuxt-link>
          <nuxt-link
            v-if="!$device.isIos || $device.isDesktop"
            :to="googlePlayUrl"
            class="download-button hover-scale-light"
            target="_blank"
          >
            <Icon size="48" name="icons:play-store-button" class="" />
          </nuxt-link>
        </div>
        <div class="qr-code-wrapper" style="opacity: 0">
          <nuxt-img
            src="/v2/qr-template.png"
            width="124"
            height="124"
            class="qr-code-image"
          />
          <div class="qr-code-notificator font--b2">Scan me</div>
        </div>

        <div class="hero-decorator-wrapper-mobile">
          <client-only>
            <Vue3Lottie
              class="hero-decorator-mobile"
              animation-link="/v2/hero6.json"
          /></client-only>
        </div>

        <div class="hero-stats">
          <div class="hero-stats-el">
            <span class="hero-stats-el-title font--b3"
              >Monthly news viewed</span
            >
            <span class="hero-stats-el-subtitle font--b1--b">15MM+</span>
          </div>

          <div class="hero-stats-el">
            <span class="hero-stats-el-title font--b3">Average app rating</span>
            <span class="hero-stats-el-subtitle font--b1--b"
              >4,6
              <base-icon name="base/star" size="24px" filled />
            </span>
          </div>

          <div class="hero-stats-el">
            <span class="hero-stats-el-title font--b3"
              >App users per month
            </span>
            <span class="hero-stats-el-subtitle font--b1--b">20M</span>
          </div>
        </div>
      </div>
      <div class="hero-decorator-wrapper">
        <client-only>
          <Vue3Lottie class="hero-decorator" animation-link="/v2/hero6.json"
        /></client-only>
      </div>
      <nuxt-img src="/v2/hero-arrow.png" alt="" class="hero-arrow" />
    </section>
  </div>
</template>

<style scoped lang="scss">
.hero-wrapper {
  position: relative;
  width: 100%;
}

.hero {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 128px 186px 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 588px;
}

.title-highlight {
  color: var(--primary-orange);
}

.subtitle {
  color: var(--grey-800);
  margin-bottom: 32px;
}

.download-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 16px;
}

.qr-code-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  margin-bottom: 90px;
}

.qr-code-notificator {
  position: relative;
  padding: 4px 8px;
  background: var(--primary-orange);
  border-radius: 4px;
  color: var(--white);
  flex-shrink: 0;
}

.qr-code-notificator::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 1%;
  z-index: 0;
  width: 10px;
  height: 10px;
  clip-path: polygon(0% 0, 100% 0%, 50% 100%);
  transform: translate(-100%, -50%) rotate(90deg);
  background-color: var(--primary-orange);
}

.hero-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
}

.hero-stats-el {
  width: 100%;
  max-width: 180px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.hero-stats-el-title {
  color: var(--grey-800);
}

.hero-stats-el-subtitle {
  display: flex;
  align-items: center;
  gap: 4px;
}

.hero-decorator-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 436px;
  height: 566px;
  margin-top: 77px;
}

.hero-decorator {
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 600px;
  height: auto;
  z-index: 1;
  transform: translate(50%, 0);
}

.hero-decorator-wrapper-mobile {
  display: none;
}

.hero-arrow {
  width: 100%;
  max-width: 34px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

@media (max-width: 1200px) {
  .hero {
    padding: 98px 0 40px 0;
    flex-direction: column;
    align-items: center;
  }

  .content {
    align-items: center;
    padding: 0 17px;
    max-width: 750px;
  }

  .title {
    text-align: center;
    padding: 0 24px;
  }

  .subtitle {
    text-align: center;
    margin-bottom: 22px;
  }

  .download-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
  }

  .qr-code-wrapper {
    display: none;
  }

  .hero-stats {
    flex-direction: column;
  }

  .hero-stats-el {
    align-items: center;
    height: auto;
  }

  .hero-decorator-wrapper {
    display: none;
  }

  .hero-decorator-wrapper-mobile {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    max-width: 500px;
    height: 302px;
  }

  .hero-decorator-mobile {
    position: absolute;
    bottom: 0;
    right: 50%;
    width: 500px;
    height: auto;
    z-index: 1;
    transform: translate(50%, 0);
  }

  .hero-arrow {
    display: none;
  }
}
</style>
