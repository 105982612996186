<script setup lang="ts">
import { useMetaMaskStore } from '~/stores/meta-mask.store';
import { useWithdrawStore } from '~/stores/withdraw.store';
import { truncateMiddle } from '~/utilities/helpers/truncate-middle';

const metaMaskStore = useMetaMaskStore();
const withdrawStore = useWithdrawStore();
const { $recaptcha } = useNuxtApp();

const DEBOUNCE_TIMEOUT = 2500; // 2500ms

const timeoutId = ref<ReturnType<typeof setTimeout> | null>(null);

const hasInteractedWithUserID = ref(false);
const hasInteractedWithUserPoints = ref(false);
const hasInteractedWithMetaMask = ref(false);
const disabledSubmit = ref(false);
const showUserPointsError = ref(false);

const showMaxButtonAndBalance = computed(() => {
  return (
    withdrawStore.isValid &&
    !withdrawStore.isChecking &&
    withdrawStore.userID !== ''
  );
});

const showUserIdError = computed(() => {
  return (
    !withdrawStore.isChecking &&
    !withdrawStore.isValid &&
    withdrawStore.userID !== ''
  );
});

const userIDError = computed(() => {
  switch (true) {
    case !hasInteractedWithUserID.value:
      return '';
    case withdrawStore.userID === '':
      return 'Enter your user ID from the Newtify app';
    case !withdrawStore.isChecking && !withdrawStore.isValid:
      return 'Invalid user ID, please check it and try again';
    default:
      return '';
  }
});

const metaMaskError = computed(() => {
  switch (true) {
    case !!metaMaskStore.metaMaskUserWallet:
      return '';
    case !metaMaskStore.metaMaskUserWallet && hasInteractedWithMetaMask.value:
      return 'To exchange, you need to connect a MetaMask wallet';
    case !!metaMaskStore.errorMessage:
      return 'Please try again';
    default:
      return '';
  }
});

const userPointsError = computed(() => {
  switch (true) {
    case !hasInteractedWithUserPoints.value:
      showUserPointsError.value = false;
      return '';
    case !withdrawStore.userPoints:
      return 'Enter amount of points for withdraw';
    case withdrawStore.userPoints && withdrawStore.userPoints < 25000:
      showUserPointsError.value = true;
      return 'Min amount for exchange 25,000';
    case withdrawStore.userPoints &&
      withdrawStore.receiveUserPoints &&
      withdrawStore.userPoints > withdrawStore.receiveUserPoints:
      showUserPointsError.value = true;
      return 'Not enough points';
    default:
      showUserPointsError.value = false;
      return '';
  }
});

const isSubmitFormDisabled = computed(() => {
  return (
    !metaMaskStore.metaMaskUserWallet ||
    !withdrawStore.isValid ||
    !withdrawStore.userPoints ||
    withdrawStore.userPoints < 25000 ||
    withdrawStore.userPoints > withdrawStore.receiveUserPoints
  );
});

const submitForm = async () => {
  //check if user has entered valid data and show error messages if not
  if (isSubmitFormDisabled.value) {
    hasInteractedWithUserID.value = true;
    hasInteractedWithUserPoints.value = true;
    hasInteractedWithMetaMask.value = true;
    onInvalidSubmit();
    return;
  }

  //get recaptcha token
  let token = '';

  if (window.grecaptcha) {
    token = await $recaptcha.reset();
  } else {
    token = await $recaptcha.execute('login');
  }

  //send request to withdraw points
  await withdrawStore.withdrawPoints(token);
};

const debouncedGetUserByID = () => {
  if (timeoutId.value) {
    // Cancel the previous request
    clearTimeout(timeoutId.value);
  }

  timeoutId.value = setTimeout(async () => {
    await withdrawStore.getUserByID();
    timeoutId.value = null;
  }, DEBOUNCE_TIMEOUT);
};

const handleInputChange = (event: Event) => {
  hasInteractedWithUserID.value = true;
  withdrawStore.isChecking = true;
  const target = event.target as HTMLInputElement;
  if (target.value === '') {
    // Don't send request if userId is empty
    withdrawStore.isValid = false;
    withdrawStore.isChecking = false;
    return;
  }
  debouncedGetUserByID();
};

const onInvalidSubmit = () => {
  disabledSubmit.value = true;
  setTimeout(() => {
    disabledSubmit.value = false;
  }, 1000);
};

const formReset = () => {
  withdrawStore.userID = '';
  withdrawStore.userPoints = null;
};

const checkKey = (e: KeyboardEvent) => {
  if (/^[eE\+\-\,\.]$/.test(e.key)) {
    e.preventDefault();
  }
};

//Formats a number to a string with mask (thousand separators).
const formatNumber = (number: number | null) => {
  if (!number) return '';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const setToMaxUserPoints = () => {
  hasInteractedWithUserPoints.value = true;
  withdrawStore.userPoints = withdrawStore.getReceivedUserPoints;
};
</script>

<template>
  <transition name="fade" mode="out-in">
    <form
      v-if="!withdrawStore.openTransactionScreen"
      class="form"
      @submit.prevent="submitForm"
    >
      <div class="form-input-box">
        <label class="form-label font--b2" for="userID">User ID</label>
        <div class="form-input-wrapper">
          <input
            id="userID"
            v-model="withdrawStore.userID"
            class="form-input form-input-id font--b2"
            :class="{
              'form-input-error': showUserIdError,
            }"
            type="text"
            placeholder="User ID from Newtify app"
            name="userID"
            @input="handleInputChange"
          >
          <div class="form-input-request-indicator">
            <transition name="fade" mode="out-in">
              <base-loader v-if="withdrawStore.isChecking" />
              <base-icon
                v-else-if="withdrawStore.isValid && withdrawStore.userID"
                name="base/valid"
                size="24px"
                filled
              />
              <base-icon
                v-else-if="
                  !withdrawStore.isValid && withdrawStore.userID !== ''
                "
                name="base/no_valid"
                size="24px"
                filled
              />
            </transition>
          </div>
          <button
            v-if="withdrawStore.userID"
            class="form-input-close-button"
            @click.prevent="withdrawStore.userID = ''"
          >
            <base-icon name="base/close_gray" size="24px" filled />
          </button>
        </div>
        <transition name="fade">
          <span v-if="userIDError" class="form-error-text font--b3">{{
            userIDError
          }}</span>
        </transition>
      </div>

      <div class="form-input-box">
        <label class="form-label font--b2">MetaMask wallet</label>
        <div
          class="connect-metamask-field"
          :class="{ 'form-input-error': metaMaskStore.errorMessage }"
        >
          <base-icon name="base/meta_mask" size="24px" filled />
          <button
            v-if="!metaMaskStore.metaMaskUserWallet"
            type="button"
            class="connect-metamask-button font--b2"
            @click.prevent="metaMaskStore.toogleMetaMaskPopup"
          >
            Connect MetaMask
          </button>
          <span class="connect-metamask-button font--b2">
            {{ truncateMiddle(metaMaskStore.metaMaskUserWallet, 5) }}
            <div
              class="form-input-request-indicator form-input-request-indicator-metamask"
            >
              <transition name="fade">
                <base-icon
                  v-if="metaMaskStore.metaMaskUserWallet"
                  name="base/valid"
                  size="24px"
                  filled
                />
              </transition>
              <transition name="fade">
                <base-icon
                  v-if="metaMaskStore.errorMessage"
                  name="base/no_valid"
                  size="24px"
                  filled
                />
              </transition>
            </div>
          </span>
        </div>
        <transition name="fade">
          <span v-if="metaMaskError" class="form-error-text font--b3">{{
            metaMaskError
          }}</span>
        </transition>
      </div>

      <div class="form-input-box">
        <div class="form-row">
          <div class="form-column form-column-points">
            <label class="form-label font--b2" for="userPoints">Points</label>
            <div class="form-input-wrapper">
              <input
                id="userPoints"
                v-model="withdrawStore.userPoints"
                class="form-input font--b2"
                :class="{
                  'form-input-error': showUserPointsError,
                  'show-max-button': showMaxButtonAndBalance,
                }"
                type="number"
                inputmode="numeric"
                placeholder="Points to withdraw"
                name="userPoints"
                @keydown="checkKey($event)"
                @input="hasInteractedWithUserPoints = true"
              >
              <div class="form-input-controls-wrapper">
                <transition name="fade">
                  <button
                    v-if="showMaxButtonAndBalance"
                    class="form-input-controls-max"
                    @click.prevent="setToMaxUserPoints"
                  >
                    MAX
                  </button></transition
                >

                <button
                  v-if="withdrawStore.userPoints"
                  class="form-input-controls-close-button"
                  @click.prevent="withdrawStore.userPoints = null"
                >
                  <base-icon name="base/close" size="18px" filled />
                </button>
              </div>
            </div>
          </div>
          <div class="form-column">
            <label class="form-label font--b2">You receive</label>
            <div class="form-result">
              <span
                class="form-result-text font--b2"
                :class="{ placeholder: withdrawStore.userReceive === '0' }"
                >{{ withdrawStore.userReceive }}</span
              >
              <span class="form-result-token font--b2">NF</span>
            </div>
          </div>
        </div>

        <transition name="fade">
          <p
            v-if="showMaxButtonAndBalance"
            class="user-id-message-valid font--b3"
          >
            Balance:
            {{
              withdrawStore.receiveUserPoints > 0
                ? formatNumber(withdrawStore.receiveUserPoints)
                : 0
            }}
            points
          </p>
        </transition>
        <transition name="fade">
          <span v-if="userPointsError" class="form-error-text font--b3">{{
            userPointsError
          }}</span>
        </transition>
      </div>
      <button
        type="submit"
        class="form-button font--b2"
        :disabled="disabledSubmit"
      >
        Exchange
      </button>
    </form>
    <withdraw-transaction v-else @reset="formReset" />
  </transition>
</template>

<style scoped lang="scss">
.form {
  position: relative;
  width: 100%;
  max-width: 588px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--card-shadow);
  padding: 32px;
  gap: 16px;
}

.form-input-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.form-label {
  margin-bottom: 4px;
}

.form-input {
  width: 100%;
  height: 48px;
  padding: 0 33px 0 10px;
  border-radius: 8px;
  border: 1px solid var(--grey-200);
  background: var(--grey-50);
  outline: none;
}

.form-input::placeholder {
  color: var(--grey-300);
}

.form-input-id {
  padding-right: 60px;
}

.form-input-wrapper {
  position: relative;
  width: 100%;
}

.form-input-close-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.form-input-request-indicator {
  position: absolute;
  top: 50%;
  right: 38px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.form-input-request-indicator-metamask {
  right: 10px;
}

.form-result {
  position: relative;
  width: 100%;
  max-width: 266px;
  height: 48px;
  padding: 0 33px 0 10px;
  border-radius: 8px;
  border: 1px solid var(--grey-200);
  background: var(--grey-50);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-result-text {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-result-text.placeholder {
  color: var(--grey-300);
}

.form-error-text {
  margin-top: 4px;
  color: var(--negative);
}

.form-result-token {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: flex-start;
}

.form-column {
  width: 100%;
  max-width: 173px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form-column-points {
  width: 100%;
  max-width: 335px;
}

.form-button {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 40px;
  background: var(--primary-orange);
  cursor: pointer;
  color: var(--white);
  outline: none;
  transition: all 0.3s ease;
}

.form-button:disabled {
  background: var(--grey-300);
  color: var(--grey-50);
  cursor: not-allowed;
}

.connect-metamask-field {
  position: relative;
  width: 100%;
  height: 48px;
  padding: 0 33px 0 10px;
  border-radius: 8px;
  border: 1px solid var(--grey-200);
  background: var(--grey-50);
  outline: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.connect-metamask-button {
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  text-decoration: underline;
  text-underline-offset: 4px;
  padding: 0;
  color: var(--black);
}

.user-id-message-valid {
  margin-top: 3px;
  color: var(--grey-800);
}

.user-points-minimum {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--negative);
  margin-top: 8px;
}

.form-input-controls-wrapper {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-input-controls-max {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  outline: none;
  color: var(--primary-orange);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-input-controls-close-button {
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  padding: 0;
}

.form-input-error {
  border: 1px solid var(--negative);
}

@media (max-width: 1200px) {
  .form {
    padding: 24px;
  }

  .form-input-id {
    padding-right: 65px;
  }

  .form-column {
    max-width: 104px;
  }

  .form-column-points {
    max-width: 450px;
  }

  .form-column-points .form-input {
    padding-right: 80px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

@media (max-width: 510px) {
  .form-column-points .form-input::placeholder {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /* When the input is showing the placeholder */
  .form-column-points .form-input:placeholder-shown {
    padding-right: 20px;
  }

  /* When focused and the placeholder is still visible */
  .form-column-points .form-input:focus:placeholder-shown {
    padding-right: 20px;
  }

  /* When start typing and clear button is showing */
  .form-column-points .form-input:focus {
    padding-right: 45px;
  }

  /* When MAX button is showing */
  .form-column-points .form-input.show-max-button {
    padding-right: 50px;
  }

  /* When MAX button is showing and focused and the placeholder is still visible */
  .form-column-points .show-max-button.form-input:focus:placeholder-shown {
    padding-right: 50px;
  }

  /* When start typing and MAX button is showing and clear button is showing */
  .form-column-points .show-max-button.form-input:focus {
    padding-right: 80px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}
</style>
