<script setup lang="ts">
interface Props {
  image: string;
  name: string;
  lastname: string;
  position: string;
  linkedin: string;
}

const props = withDefaults(defineProps<Props>(), {});
</script>

<template>
  <div class="card">
    <nuxt-img :src="props.image" alt="team member" class="card__image" />
    <div class="card__content">
      <div class="card__info">
        <div class="card__name font--b1--b">{{ props.name }}</div>
        <div class="card__lastname font--b1--b">{{ props.lastname }}</div>
        <div class="card__position font--b2">{{ props.position }}</div>
      </div>
      <nuxt-link
        :to="props.linkedin"
        target="_blank"
        class="card__linkedin hover-scale-light"
      >
        <base-icon name="base/social_linkedin" size="32px" filled />
      </nuxt-link>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card {
  width: 100%;
  max-width: 384px;
  height: auto;
  border-radius: 24px;
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.card__image {
  width: 100%;
  max-width: 320px;
  min-width: 272px;
}

.card__content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.card__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.card__position {
  color: var(--grey-800);
  margin-top: 8px;
}

.card__linkedin {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--primary-orange);
}
</style>
