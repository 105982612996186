import { ExchangeApi } from './exchange.api';
import type { PostExchangeDTO } from '~/types/exchange';

export const ExchangeService = new (class {
  async getUserByID(id: string) {
    const response = await ExchangeApi.getUser(id);
    return response;
  }

  async ExhangePoints(data: PostExchangeDTO) {
    const response = await ExchangeApi.postExchange(data);
    return response;
  }

  async getUserReferralLink(id: string) {
    const response = await ExchangeApi.getUserReferralLink(id);
    return response;
  }
})();
