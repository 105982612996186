<script setup lang="ts">
import useMetaMask from '~/composables/meta-mask';
import { useClipboard } from '~/utilities/helpers/clipboard';

const { addCustomToken } = useMetaMask();
interface Emits {
  (e: 'close'): void;
}
const emit = defineEmits<Emits>();

const { copy, isCopied } = useClipboard();

const handleCopy = () => {
  copy('0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE');
};
</script>

<template>
  <div class="popup-add-nf-token">
    <div class="popup-column">
      <h2 class="font--h3">
        Add nf TOKEN<br >
        TO YOUR METAMASK
      </h2>
      <div class="popup-way">
        <h3 class="font--b2">
          <span class="highlight font--b2--b">Way 1:</span> Click the button
          below and token will be added automaticity
        </h3>

        <button
          class="popup-metamask-button font--b2 hover-scale-light"
          @click="addCustomToken"
        >
          <base-icon name="base/meta_mask" size="25px" filled/>
          Add NF to Metamask
        </button>
      </div>
      <div class="popup-way">
        <h3 class="font--b2">
          <span class="highlight font--b2--b">Way 2:</span> Add token manually
        </h3>
        <ul class="popup-way-list">
          <li class="font--b2">1. Open MetaMask on Polygon Mainnet.</li>
          <li class="font--b2">2. Click "Import" in the Tokens tab.</li>
          <li class="popup-way-address-wrapper">
            <p class="font--b2">3. Enter Contract Address:</p>
            <div class="popup-way-address">
              <span class="popup-way-address-text font--b3"
                >0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE</span
              >
              <transition name="fade" mode="out-in">
                <button
                  v-if="!isCopied"
                  class="copy-adrress hover-scale-light"
                  @click="handleCopy"
                >
                  <base-icon name="base/copy_blue" size="24px" filled />
                </button>
                <base-icon
                  v-else
                  name="base/valid"
                  size="24px"
                  filled
                />
              </transition>
            </div>
          </li>
          <li class="font--b2">4. Click "Next" and "Import".</li>
          <li class="font--b2">5. NF Tokens appear in your wallet.</li>
        </ul>
      </div>
    </div>
    <div class="instruction-wrapper">
      <iframe
        width="315"
        height="560"
        src="https://www.youtube.com/embed/B5YiBjMJehA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </div>
    <button class="popup-close-button" @click="emit('close')">
      <base-icon
        name="base/close_gray"
        size="32px"
        color="var(--grey-300)"
      />
    </button>
  </div>
</template>

<style scoped lang="scss">
.popup-add-nf-token {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  border-radius: 24px;
  background: var(--white);
  box-shadow: var(--card-shadow);
  padding: 40px;
}

.popup-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.popup-way {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.popup-metamask-button {
  width: fit-content;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px 24px;
  text-align: center;
  border-radius: 40px;
  background-color: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.popup-way-list {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.popup-way-address-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.popup-way-address {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.popup-way-address-text {
  color: var(--secondary);
  text-decoration-line: underline;
  text-underline-offset: 4px;
}

.instruction-wrapper {
  width: 100%;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instruction-wrapper iframe {
  border-radius: 32px;
}

.copy-adrress {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.popup-close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  padding: 0;
}

.highlight {
  color: var(--primary-orange);
}

@media (max-width: 1200px) {
  .popup-add-nf-token {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 15px;
  }

  .popup-column {
    align-items: center;
  }

  .popup-column h2 {
    text-align: center;
  }

  .popup-way {
    gap: 10px;
  }

  .popup-way-list {
    gap: 10px;
  }

  .popup-way-address-text {
    word-break: break-all;
  }

  .popup-close-button :deep(.base-icon) {
    width: 24px;
    height: 24px;
  }

  .popup-close-button :deep(.base-icon) .nuxt-icon {
    width: 24px;
    height: 24px;
  }

  .popup-close-button :deep(.base-icon) .nuxt-icon svg {
    width: 24px;
    height: 24px;
  }
}
</style>
