<script setup lang="ts">
import { useClipboard } from '~/utilities/helpers/clipboard';
const { copy, isCopied } = useClipboard();

const handleCopy = () => {
  copy('0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE');
};
</script>

<template>
  <div class="trust-wrapper">
    <section class="trust">
      <h2 class="trust-title font--h2">We are Trusted</h2>
      <div class="trust-content">
        <div class="trust-content-top">
          <h3 class="trust-content-top-title font--h3">Smart contract:</h3>
          <div class="adrress-wrapper">
            <span class="adrress font--b1"
              >0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE</span
            >
            <transition name="fade" mode="out-in">
              <button
                v-if="!isCopied"
                class="copy-adrress hover-scale-light"
                @click="handleCopy"
              >
                <base-icon name="base/copy_big" size="32px" filled />
              </button>
              <base-icon
                v-else
                name="base/valid_white"
                size="32px"
                filled
              />
            </transition>
          </div>
        </div>

        <div class="trust-content-bottom">
          <nuxt-link
            to="https://polygonscan.com/token/0x1f4cb3949f7f5eee736e271d07cc6ff63098beae"
            class="trust-link hover-scale-light font--b2"
          >
            Smart Contract - Polygonscan
            <base-icon name="base/arrow" size="24px" filled />
          </nuxt-link>
          <nuxt-link
            to="https://app.uniswap.org/explore/pools/polygon/0x9657655103F382490C4A70ebc8761c5c6dc9BEC5"
            class="trust-link hover-scale-light font--b2"
          >
            Liquidity Pool on Uniswap
            <base-icon name="base/arrow" size="24px" filled />
          </nuxt-link>
          <nuxt-link
            to="https://coinmarketcap.com/dexscan/polygon/0x9657655103f382490c4a70ebc8761c5c6dc9bec5/"
            class="trust-link hover-scale-light font--b2"
          >
            Listing on CoinMarketCap
            <base-icon name="base/arrow" size="24px" filled />
          </nuxt-link>
          <nuxt-link
            to="https://www.geckoterminal.com/polygon_pos/pools/0x9657655103f382490c4a70ebc8761c5c6dc9b[…]e=coingecko&utm_medium=referral&utm_campaign=searchresults"
            class="trust-link hover-scale-light font--b2"
          >
            Listing on CoinGecko
            <base-icon name="base/arrow" size="24px" filled />
          </nuxt-link>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.trust-wrapper {
  position: relative;
  width: 100%;
}

.trust {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.trust-title {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.trust-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 24px;
  background-color: var(--primary-orange);
  box-shadow: var(--card-shadow);
  height: 400px;
  padding: 32px;
  background-image: url('/v2/trust-decorator.png');
  background-repeat: no-repeat;
  background-position: 98% 65%;
  background-size: 32.3%;
}

.trust-content-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.trust-content-top-title {
  color: var(--white);
}

.adrress-wrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.adrress {
  color: var(--white);
  text-decoration-line: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 1px;
}

.copy-adrress {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

.trust-content-bottom {
  width: 100%;
  max-width: 750px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.trust-link {
  color: var(--white);
  text-decoration: none;
  border-radius: 40px;
  background: var(--black);
  height: 44px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (max-width: 1200px) {
  .trust {
    padding: 40px 24px;
    max-width: 750px;
  }

  .trust-title {
    margin-bottom: 24px;
  }

  .trust-content {
    height: 720px;
    justify-content: unset;
    gap: 40px;
    padding: 32px 24px;
    background-position: 100% 97%;
    background-size: 342px;
  }

  .adrress-wrapper {
    align-items: flex-start;
  }

  .adrress {
    word-break: break-all;
    text-underline-offset: 4px;
  }

  .trust-content-bottom {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
