<script setup lang="ts">
const isShown = defineModel('isShown', {
  type: Boolean,
});
</script>

<template>
  <button class="button font--b2 hover-scale-light" @click="isShown = !isShown">
    <slot v-if="!isShown" name="show"></slot>
    <slot v-if="isShown" name="hide"></slot>
  </button>
</template>

<style lang="scss">
.button {
  width: fit-content;
  height: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  transition: all 0.3s;
  color: var(--primary-orange);
  text-decoration: underline;
  text-underline-offset: 5px;
}
</style>
