<script setup lang="ts">
import useUtmParams from '~/composables/use-utm-params';

const { utm_source, utm_medium, utm_campaign } = useUtmParams();

const googlePlayUrl = computed(() => {
  return `http://play.google.com/store/apps/details?id=io.newtify.app&referrer=utm_source%3D${utm_source}%26utm_medium%3D${utm_medium}%26utm_campaign%3D${utm_campaign}`;
});
</script>

<template>
  <div class="banner-wrapper">
    <section class="banner">
      <div class="banner-content">
        <div class="banner-content-text">
          <h2 class="font--h2 banner-content-title">install the app</h2>
          <span class="font--b1 banner-content-subtitle"
            >And start earning right now</span
          >
        </div>
        <div class="banner-buttons-wrapper">
          <nuxt-link
            v-if="$device.isIos || $device.isDesktop"
            to="https://apps.apple.com/us/app/newtify-io/id6482849312"
            class="download-button hover-scale-light"
            target="_blank"
          >
            <Icon size="48" name="icons:app-store-button" class="" />
          </nuxt-link>
          <nuxt-link
            v-if="!$device.isIos || $device.isDesktop"
            :to="googlePlayUrl"
            class="download-button hover-scale-light"
            target="_blank"
          >
            <Icon size="48" name="icons:play-store-button" class="" />
          </nuxt-link>
          <nuxt-img
            style="display: none"
            v-if="$device.isDesktop"
            src="/v2/qr-template.png"
            width="92"
            height="92"
            class="qr-code-image"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.banner-wrapper {
  position: relative;
  width: 100%;
}

.banner {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.banner-content {
  width: 100%;
  height: 140px;
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: var(--primary-orange);
  background-image: url('/v2/banner-bg.png');
  background-repeat: no-repeat;
  background-position: 8% 13%;
  background-size: 120%;
}

.banner-content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.banner-content-title {
  color: var(--white);
}

.banner-content-subtitle {
  color: var(--white);
}

.banner-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

@media (max-width: 1200px) {
  .banner {
    padding: 40px 24px;
    max-width: 750px;
  }

  .banner-content {
    flex-direction: column;
    height: auto;
    padding: 24px;
    background-position: 40% 58%;
    background-size: 270%;
  }

  .banner-content-subtitle {
    margin-bottom: 16px;
  }
}
</style>
