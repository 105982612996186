<script setup lang="ts">
const isDragging = ref(false);
const startX = ref(0);
const scrollLeft = ref(0);
const reviewContentFirst = ref<HTMLElement | null>(null);
const reviewContentSecond = ref<HTMLElement | null>(null);

const handleMouseDownFirst = (e: MouseEvent) => {
  if (!reviewContentFirst.value) return;
  e.preventDefault();
  isDragging.value = true;
  startX.value = e.pageX;
  scrollLeft.value = reviewContentFirst.value.scrollLeft;
  window.addEventListener('mousemove', handleMouseMoveFirst);
  window.addEventListener('mouseup', handleMouseUpFirst);
};

const handleMouseMoveFirst = (e: MouseEvent) => {
  if (!isDragging.value || !reviewContentFirst.value) return;
  e.preventDefault();
  const x = e.pageX;
  const walk = x - startX.value;
  reviewContentFirst.value.scrollLeft = scrollLeft.value - walk;
};

const handleMouseUpFirst = () => {
  isDragging.value = false;
  window.removeEventListener('mousemove', handleMouseMoveFirst);
  window.removeEventListener('mouseup', handleMouseUpFirst);
};

const handleMouseDownSecond = (e: MouseEvent) => {
  if (!reviewContentSecond.value) return;
  e.preventDefault();
  isDragging.value = true;
  startX.value = e.pageX;
  scrollLeft.value = reviewContentSecond.value.scrollLeft;
  window.addEventListener('mousemove', handleMouseMoveSecond);
  window.addEventListener('mouseup', handleMouseUpSecond);
};

const handleMouseMoveSecond = (e: MouseEvent) => {
  if (!isDragging.value || !reviewContentSecond.value) return;
  e.preventDefault();
  const x = e.pageX;
  const walk = x - startX.value;
  reviewContentSecond.value.scrollLeft = scrollLeft.value - walk;
};

const handleMouseUpSecond = () => {
  isDragging.value = false;
  window.removeEventListener('mousemove', handleMouseMoveSecond);
  window.removeEventListener('mouseup', handleMouseUpSecond);
};

const reviewsFirst = [
  { src: '/v2/review1.png', alt: 'Review 1' },
  { src: '/v2/review2.png', alt: 'Review 2' },
  { src: '/v2/review3.png', alt: 'Review 3' },
  { src: '/v2/review4.png', alt: 'Review 4' },
  { src: '/v2/review5.png', alt: 'Review 5' },
  { src: '/v2/review1.png', alt: 'Review 1' },
  { src: '/v2/review2.png', alt: 'Review 2' },
  { src: '/v2/review3.png', alt: 'Review 3' },
  { src: '/v2/review4.png', alt: 'Review 4' },
  { src: '/v2/review5.png', alt: 'Review 5' },
  { src: '/v2/review1.png', alt: 'Review 1' },
  { src: '/v2/review2.png', alt: 'Review 2' },
  { src: '/v2/review3.png', alt: 'Review 3' },
  { src: '/v2/review4.png', alt: 'Review 4' },
  { src: '/v2/review5.png', alt: 'Review 5' },
];

const reviewsSecond = [
  { src: '/v2/review6.png', alt: 'Review 6' },
  { src: '/v2/review7.png', alt: 'Review 7' },
  { src: '/v2/review8.png', alt: 'Review 8' },
  { src: '/v2/review9.png', alt: 'Review 9' },
  { src: '/v2/review10.png', alt: 'Review 10' },
  { src: '/v2/review11.png', alt: 'Review 11' },
  { src: '/v2/review12.png', alt: 'Review 12' },
  { src: '/v2/review6.png', alt: 'Review 6' },
  { src: '/v2/review7.png', alt: 'Review 7' },
  { src: '/v2/review8.png', alt: 'Review 8' },
  { src: '/v2/review9.png', alt: 'Review 9' },
  { src: '/v2/review10.png', alt: 'Review 10' },
  { src: '/v2/review11.png', alt: 'Review 11' },
  { src: '/v2/review12.png', alt: 'Review 12' },
  { src: '/v2/review6.png', alt: 'Review 6' },
  { src: '/v2/review7.png', alt: 'Review 7' },
  { src: '/v2/review8.png', alt: 'Review 8' },
  { src: '/v2/review9.png', alt: 'Review 9' },
  { src: '/v2/review10.png', alt: 'Review 10' },
  { src: '/v2/review11.png', alt: 'Review 11' },
  { src: '/v2/review12.png', alt: 'Review 12' },
];
</script>

<template>
  <div class="review-wrapper">
    <section class="review">
      <h2 class="review-title font--h2">Reviews</h2>
      <div class="review-content-wrapper">
        <div ref="reviewContentFirst" class="review-content">
          <div
            class="review-content-row"
            @mousedown="handleMouseDownFirst"
            @mouseup="handleMouseUpFirst"
            @mousemove="handleMouseMoveFirst"
          >
            <nuxt-img
              v-for="(review, index) in reviewsFirst"
              :key="index"
              :src="review.src"
              :alt="review.alt"
              class="review-image"
            />
          </div>
        </div>
        <div ref="reviewContentSecond" class="review-content">
          <div
            class="review-content-row review-content-row-second"
            @mousedown="handleMouseDownSecond"
            @mouseup="handleMouseUpSecond"
            @mousemove="handleMouseMoveSecond"
          >
            <nuxt-img
              v-for="(review, index) in reviewsSecond"
              :key="index"
              :src="review.src"
              :alt="review.alt"
              class="review-image"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.review-wrapper {
  position: relative;
  width: 100%;
}

.review {
  position: relative;
  width: 100%;
  padding: 80px 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.review-title {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}

.review-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.review-content {
  width: 100%;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.review-content::-webkit-scrollbar {
  display: none;
}

.review-content-row {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  animation: marquee 20s linear infinite;
  cursor: grab;
}

.review-content-row-second {
  animation: marquee-second 20s linear infinite;
}

.review-content-row:active {
  cursor: grabbing;
  animation-play-state: paused;
}

.review-image {
  width: auto;
  height: 160px;
  flex-shrink: 0;
}

@media (max-width: 1200px) {
  .review {
    padding: 40px 0;
  }

  .review-content-wrapper {
    gap: 8px;
  }

  .review-content-row {
    gap: 8px;
    animation: marquee-mobile 20s linear infinite;
    width: 2092px;
  }

  .review-content-row-second {
    width: 2077px;
    animation: marquee-second-mobile 20s linear infinite;
  }

  .review-image {
    height: 96px;
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-3505px, 0);
  }
}

@keyframes marquee-second {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-3480px, 0);
  }
}

@keyframes marquee-mobile {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-2092px, 0);
  }
}

@keyframes marquee-second-mobile {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-2077px, 0);
  }
}
</style>
