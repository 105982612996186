<script setup lang="ts">
const showInstruction = ref(false);

const iframeSrc = ref('');

const iframeElement = ref(null);

const targetSrc = 'https://app.uniswap.org/#/swap?inputCurrency=0x1F4cb3949F7f5eeE736E271D07CC6ff63098bEaE&outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&chain=polygon&theme=dark';

onMounted(() => {
  if (iframeElement.value) {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && iframeElement.value) {
          iframeSrc.value = targetSrc; // Load the iframe content
            observer.unobserve(iframeElement.value); // Stop observing
        }
      },
      {
        threshold: 0.1, // Trigger when 10% of the iframe is visible
      }
    );
    observer.observe(iframeElement.value); // Start observing the iframe
  }
});
</script>

<template>
  <div class="swap-wrapper">
    <section class="swap">
      <h2 class="swap-title font--h2">swap</h2>
      <h3 class="swap-subtitle font--h3">nf to usdt</h3>
      <div class="swap-content-wrapper">
        <div class="swap-content">
          <iframe
            ref="iframeElement"
            :src="iframeSrc"
            height="650"
            width="100%"
            tabindex="-1"
            style="
              border: 0;
              margin: 0 auto;
              display: block;
              border-radius: 10px;
              max-width: 600px;
              min-width: 300px;
            "
          />
          <base-button-show v-model:is-shown="showInstruction">
            <template #show>View video-instruction</template>
            <template #hide>Hide video-instruction</template>
          </base-button-show>
        </div>
        <transition name="fade">
          <div v-if="showInstruction" class="instruction-wrapper">
            <iframe
              width="315"
              height="560"
              src="https://www.youtube.com/embed/PZf5QGmltIU"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
.swap-wrapper {
  position: relative;
  width: 100%;
}

.swap {
  position: relative;
  width: 100%;
  max-width: 1440px;
  padding: 80px 120px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swap-subtitle {
  color: var(--primary-orange);
  text-align: center;
  margin-bottom: 40px;
}

.swap-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.swap-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.instruction-wrapper {
  width: 100%;
  max-width: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.instruction-wrapper iframe {
  border-radius: 32px;
}

@media (max-width: 1200px) {
  .swap {
    padding: 40px 24px;
    max-width: 750px;
  }

  .swap-subtitle {
    margin-bottom: 24px;
  }

  .swap-content-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}
</style>
